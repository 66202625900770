import trackLink from '@99designs/common/utils/platform/trackLink';

(function ($) {
    enableLinkTracking(
        $('[data-related-content-interjection-link]'),
        'Clicked Related Content Link On Blog',
        {
            link_type: 'content-interjection',
        }
    );

    function enableLinkTracking($links, event, data) {
        $links.each(function (index, link) {
            trackLink(link, event, data);
        });
    }
})(jQuery);
